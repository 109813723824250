import React from "react"
import { Container} from "react-bootstrap"
import Img from "gatsby-image"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import LearnMore from "../components/learnmore"
import ScrollReveal from 'scrollreveal'

const Cases = () => {
  React.useEffect(()=> {
    ScrollReveal().reveal('.case-foo',
      {
        delay: 0,
        distance: '50px',
        easing: 'ease-in-out',
        origin: "bottom",
        interval: 50,
        duration: 400,
        mobile: false,
        opacity : 0, 
        reset: false,
        init: false
      }
    )
  })
  const data = useStaticQuery(graphql`
    query {
      case1:file(relativePath: { eq: "cases/1.png" }) {
        childImageSharp {
          fluid (maxWidth:1920){
            ...GatsbyImageSharpFluid
          }
        }
      }
      case2:file(relativePath: { eq: "cases/2.png" }) {
        childImageSharp {
          fluid (maxWidth:1920){
            ...GatsbyImageSharpFluid
          }
        }
      }
      case3:file(relativePath: { eq: "cases/3.png" }) {
        childImageSharp {
          fluid (maxWidth:1920){
            ...GatsbyImageSharpFluid
          }
        }
      }
      case4:file(relativePath: { eq: "cases/4.png" }) {
        childImageSharp {
          fluid (maxWidth:1920){
            ...GatsbyImageSharpFluid
          }
        }
      }
      case5:file(relativePath: { eq: "cases/5.png" }) {
        childImageSharp {
          fluid (maxWidth:1920){
            ...GatsbyImageSharpFluid
          }
        }
      }
      case6:file(relativePath: { eq: "cases/6.png" }) {
        childImageSharp {
          fluid (maxWidth:1920){
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout pageInfo={{ pageName: "case" }}>
      <SEO title="Featured Store" description="Shoprises offers data-optimized website themes which offer better sales performance compared to others." path="cases" />
      <Container className='cases'>
        <div className='title case-foo'>Website Themes</div>
        <div className='text case-foo'>Shoprises offers data-optimized website themes which offer better sales performance compared to others.</div>
        <div className='imgs'>
          {
            data && Object.keys(data).map(item => (
              <Img
                fluid={data[item].childImageSharp.fluid}
                className='case-img case-foo'
                key={item}
              />
            ))
          }
          {/* {
            data && Object.keys(data).map(item => (
              <div key={item} onClick={()=>setItem(data[item].childImageSharp.fluid)} className='case-img'>
                <Img
                  fluid={data[item].childImageSharp.fluid}
                  // className='case-img'
                />
              </div>
            ))
          } */}
        </div>
        {/* <div onClick={()=>setItem('')}>
          <Img 
            className='case-img-big'
            fluid={item}
            style={{display:item===''?'none':'block'}}
          />
        </div> */}

      </Container>
      {/* 二级页脚 */}
      {/* <LearnMore /> */}
    </Layout>
  )
}

export default Cases

